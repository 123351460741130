.library {
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    background: white;
    box-shadow: 2px 2px 50px rgb(197, 197, 197);
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    h2 {
      padding: 2rem;
    }
  }
  .library-song {
    display: flex;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    cursor: pointer;
    transition: background 0.5s ease;   
    img {
      width: 30%;
    }
    &:hover {
      background-color: rgb(223, 215, 255);
    }
  }
  .song-description {
    padding-left: 1rem;
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.7rem;
    }
  }
  
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
  .selected {
    background: rgb(146, 199, 248);
  }
  .active-library {
    transform: translateX(0%);
    z-index: 1;
    opacity: 1;
  }
  @media screen and (max-width: 768px) {
    .library {
      width: 100%;
    }
  }